<template>
  <div class="speed-legend left shadow">
    <div class="speed-legend__header">
      <span class="truncate-text truncate">{{ unitName }}</span>
      <div class="speed-legend__title">
        <span>{{ $t('tracking.speed.legend.mileage') }}: {{ mileage }} {{ $t('tracking.speed.legend.km') }}</span>
      </div>
    </div>
    <div class="speed-legend__speed-title">
      <span class="">{{ $t('notifications.speed') }} ({{ $t('km_hour') }}) :</span>
    </div>
    <div class="speed-legend__content">
      <div v-for="(segment, index) in segmentWidths" :key="index" class="speed-legend__line" :style="segment"></div>
    </div>
    <div class="speed-legend__footer">
      <div v-for="(label, index) in rangeLabels" :key="index" :class="['speed-legend__footer-item', { 'align-right': index === rangeLabels.length - 1 }]">
        {{ label }}
      </div>
    </div>

    <div class="speed-legend__details">
      <el-divider class="devider"></el-divider>
      <div class="speed-legend__content">
        <el-checkbox v-model="hideTrackInfo" />
        <span class="details">
          {{ $t('hide.tracks.signatures') }}
          ({{ $t('hide.tracks.direction_of_movement') }})
        </span>
      </div>
      <span @click="closeFastTrack" class="hideTrack">{{ $t('fast_track.close') }}</span>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import trackDirection from '@/mixins/trackDirection'
import { toggleOpenedBoxTrack } from '@/services/reports/boxTrackReport'
import { MapInstance } from '@/services/map'

export default {
  mixins: [trackDirection],
  props: {
    unitName: {
      type: String,
      default: ''
    },
    mileage: {
      type: [Number, String],
      default: 0
    },
    isMonitoring: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hideTrackInfo: false,
      arrowsOnTrack: [],
      trekSignature: [],
      map: null,
    }
  },
  computed: {
    ...mapGetters({
      isHideSignatures: 'map/hideSignatures',
      getBoxTrack: 'monitoring/getBoxTrack'
      // trackColors: 'map/trackColors'
    }),
    ...mapState('reports', {
      reportCurrentTrack: state => state.track
    }),

    segmentWidths() {
      const numColors = this.trackColors.length
      const percentage = 100 / numColors
      return this.trackColors.map(color => ({ backgroundColor: color.hex, width: `${percentage}%` }))
    },
    /**
     * get the track colors based on REPORT or MONITORING(box-track).
     *
     * @return {Array} array of track colors
     */
    trackColors() {
      let trackNewStyles
      const track = this.isMonitoring ? Object.values(this.getBoxTrack)[0] : this.reportCurrentTrack.track
      trackNewStyles = track.legenda.newStyles
      return trackNewStyles
    },
    rangeLabels() {
      const labels = this.trackColors.map((color, index) => {
        return index === 0 ? '0' : this.trackColors[index - 1].max
      })
      labels.push('∞')
      return labels
    }
  },
  watch: {
    hideTrackInfo(val) {
      this.$store.commit('map/TOGGLE_TREK_INFO', val)
    }
  },
  methods: {
    closeFastTrack() {
      console.log('closeFastTrack')
      toggleOpenedBoxTrack(false)
      this.$store.commit('map/EYE_TRIGGER', 'tracks')
    }
  },

  mounted() {
    this.map = MapInstance.getMap();

    if (this.isHideSignatures) {
      this.$store.commit('map/TOGGLE_TREK_INFO', true)
      this.hideTrackInfo = true
    }
  },
}
</script>

<style lang="scss" scoped>
.speed-legend__details {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #a6aeb8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 6px;
  padding-bottom: 6px;

  .devider {
    margin: 0 0 7px 0;
    padding: 0;
    background: #dce1e7;
    height: 1px;
  }

  .speed-legend__content {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 100%;
    .details {
      margin-left: 7px;
    }
  }

  .hideTrack {
    color: #c7472b;
    margin-top: 11px;
    font-weight: 400;
    display: inline-block;
    cursor: pointer;
    &:hover {
      border-bottom: 1px solid #c7472b;
      transition: border 0.5s ease;
    }
  }
}

.speed-legend__title {
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #828d9b;
  line-height: 14px;
  .truncate-text {
    max-width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
  }
}

.backtotrack.left,
.speed-legend.left {
  right: 21px;
}

.speed-legend {
  position: absolute;
  top: 80px;
  background-color: #fff;
  width: 244px;
  padding: 3px 20px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 12px;
  z-index: 9;
}

.speed-legend__speed-title {
  padding: 2px;
  border-top: 1px solid #dce1e7;
  text-align: center;
  padding-top: 6px;
}

.speed-legend__header {
  text-align: center;
  padding: 6px 0;
  padding-bottom: 0;
}

.speed-legend__footer {
  display: flex;
}

.speed-legend__footer-item {
  width: 70px;
  text-align: left;
  &.align-right {
    text-align: right;
    font-size: 18px;
    font-weight: 600;
    line-height: 15.4px;
  }
}

.speed-legend__content {
  display: flex;
}

.speed-legend__line {
  width: 70px;
  text-align: center;
  height: 8px;
  background-color: #000;
  margin-bottom: 6px;
  text-align: center;
  height: 8px;
  margin-bottom: 6px;
  border-radius: 0;
  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
</style>
